import React, {useEffect, useRef, useState} from 'react';
import {Form} from 'react-bootstrap';
import CustomSlidingButtons from '../CustomSlidingButtons';
import TextareaAutosize from 'react-textarea-autosize';
import SlidingPanel from '../SlidingPanel';
import {RoiLogo} from '../../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';

const CallMessages = props => {
  const {showMessage, onClose} = props;

  
  const messages = [
    {
      id: 1,
      type: "sender", // Can be "sender" or "receiver"
      userImage: "https://s3.amazonaws.com/build-dev/profile_img/1698304433145_thumb.jpg",
      username: "Hello W.",
      time: "2:33 AM",
      text: "@M D. fasfdsfa",
      date: "23/08/2024",
    },
    {
      id: 2,
      type: "receiver",
      userImage: "https://s3.amazonaws.com/build-dev/profile_img/1698304433145_thumb.jpg",
      username: "Hello W.",
      time: "2:35 AM",
      text: "you",
      date: "23/08/2024",
    },
    {
      id: 3,
      type: "sender",
      userImage: "https://s3.amazonaws.com/build-dev/profile_img/1698304433145_thumb.jpg",
      username: "Hello W.",
      time: "2:37 AM",
      text: "Sample text for sender message.",
      date: "23/08/2024",
    },
  ];

  const [showComponent, setShowComponent] = useState('All');
  const [writtenMsg, setWrittenMsg] = useState('');
  const [messageBody, setMessageBody] = useState(messages);
  const scrollRef = useRef(null)

  const TabList = [
    {id: 'All', title: 'All'},
    {id: 'Images', title: 'Images'},
    {id: 'Document', title: 'Document'},
    {id: 'Videos', title: 'Videos'},
    {id: 'Videosss', title: 'Videosss'},
  ];

  const handleType = e => {
    const {id, value} = e.target;
    setShowComponent(value);
  };

  const renderProjeectButtons = profile => (
    <>
      <img src={RoiLogo} alt="" />
    </>
  );

  const saveMessage = (value) => {
    const NewData= {
      id: 1,
      type: "sender", // Can be "sender" or "receiver"
      userImage: "https://s3.amazonaws.com/build-dev/profile_img/1698304433145_thumb.jpg",
      username: "Hello W.",
      time: "2:33 AM",
      text: value,
      date: "23/08/2024",
    }
    console.log(NewData,'NewDataNewData')

    setMessageBody([...messageBody, NewData]);
    setWrittenMsg('');
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToBottom();
    }
  }, [messageBody])


  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          onClose();
        }}
        renderData={renderProjeectButtons}
        showBlackHeader={true}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#F7F6F2',
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)' /*linear-gradient(to bottom, black, white)*/,
        }}>
        <div className="messages-wrapper fix-width-forms message-chat-box-holder">
          <div className="mt-2">
            <div className="profile-links-btns success-decline-btn-block" />
            <div className="message-profile-heading-nospace-block message-profile-smart-chat-head-block">
              <div className="messages-profile-heading message-profile-heading-nospace no-space">
                <div className="profile-img">
                  {' '}
                  <img
                    src="https://s3.amazonaws.com/build-dev/group_image/1724394743640.jpg"
                    alt=""
                    className="shadow-none"
                  />
                </div>
                <div className="profile-content">
                  <div className="user-name my-username">
                    <h2 className="">{showMessage}</h2>
                  </div>
                 
                </div>
              </div>
              <div className="profile-head-icons-block">
                <span className="icon threeDots">
                  <i className="fas fa-ellipsis-vertical" />
                </span>
              </div>
            </div>
          </div>
          <div className="category-btns-wrapper category-btns-wrapper-slider mt-2 mb-4">
            <CustomSlidingButtons
              items={TabList}
              activeButtons={showComponent}
              onItemClick={e => {
                handleType(e);
              }}
              showItems={4}
              value={showComponent}
            />
          </div>

          <Scrollbars ref={scrollRef}>
            <div className="messages"
            style={{paddingRight:'17px'}}>
              <div className="messages-list">
                <div className="message-day-saparator">23/08/2024</div>
                {messageBody && messageBody.length > 0 && messageBody.map((message) => (
                <div className={`message-item new-msg-item   ${message.type === "sender" && "reply-message"}`}>
                  <div className="msg-container">
                    <div className="msg-content">
                      {message.type !== "sender"  && 
                      <div className="msg-content-left">
                        <div className="msg-usr-img ">
                          <img
                            src={message.userImage}
                            alt=""
                            className="shadow-none"
                          />
                        </div>
                      </div>}
                      <div className={`msg-content-right ${message.type === "sender" && "msg-smartchat-contnt-right"}` }>
                        <h2>
                          {message.username} <span className="msg-date">{message.date}</span>
                        </h2>
                        <div className="msg-content-text">
                          <p>{message.text}</p>
                          <div className="emoji-icons-wrapper">
                            <div className="emoji-icons d-flex align-items-center" />
                          </div>
                          <div className="snapshot-imgs-wrapper" />
                        </div>
                      </div>
                    </div>
                  
                    <div className="msg-user-replies-wrapper" />
                  </div>
                </div>
                ))}
                
              </div>
             
              <div />
            </div>
          </Scrollbars>

          <div className="message-form message-smartchat-form">
            <input
              type="file"
              accept="video/*"
              name="myImage"
              style={{display: 'none'}}
            />
            <span className="form-icon text-yellow">
              <i className="fas fa-ellipsis-vertical" />
            </span>
            <div className="form-input">
              <Form.Group className="form-input">
                <TextareaAutosize
                  maxRows={4}
                  minRows={1}
                  className="form-control"
                  name="message"
                  value={writtenMsg}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      saveMessage(e.target.value);
                    }
                  }}
                  onChange={e => {
                    setWrittenMsg(e.target.value);
                  }}
                  placeholder="Type your message here"
                />
              </Form.Group>
            </div>
            <span
              onClick={() => {
                saveMessage(writtenMsg);
              }}
              className="form-icon send-btn">
              <i className="fa-solid icon-plane" />
            </span>
          </div>
        </div>
      </SlidingPanel>
    </>
  );
};

export default CallMessages;
