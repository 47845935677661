const messages = [
    {
      id: 1,
      userImage: "https://s3.amazonaws.com/build-dev/company_images/1723540645548.jpg",
      title: "New PCOS",
      content: "dsfsfdsa",
      dateTime: "2024-11-14T08:46:25", // ISO format for date/time
      formattedDate: "11/14/24 08:46 AM",
    },
    {
      id: 2,
      userImage: "https://s3.amazonaws.com/build-dev/company_images/1723540645548.jpg",
      title: "Health Tips",
      content: "Stay hydrated",
      dateTime: "2024-11-14T10:15:00",
      formattedDate: "11/14/24 10:15 AM",
    },
    {
      id: 3,
      userImage: "https://s3.amazonaws.com/build-dev/company_images/1723540645548.jpg",
      title: "Diet Plan",
      content: "Low carb meals",
      dateTime: "2024-11-14T11:00:00",
      formattedDate: "11/14/24 11:00 AM",
    },
    {
      id: 3,
      userImage: "https://s3.amazonaws.com/build-dev/company_images/1723540645548.jpg",
      title: "Diet Plan",
      content: "Low carb meals",
      dateTime: "2024-11-14T11:00:00",
      formattedDate: "11/14/24 11:00 AM",
    },
    {
      id: 3,
      userImage: "https://s3.amazonaws.com/build-dev/company_images/1723540645548.jpg",
      title: "Diet Plan",
      content: "Low carb meals",
      dateTime: "2024-11-14T11:00:00",
      formattedDate: "11/14/24 11:00 AM",
    },
  ];
  
  const TwillioSMS = () => {
    return (
      <div>
        {messages.map((message) => (
          <div
            key={message.id}
            className="message-user-item no-link likes-dislikes-msgs"
          >
            <div className="user-img as-link">
              <img src={message.userImage} alt={message.title} />
            </div>
            <div className="user-content as-link">
              <h2 className="single-line-text">{message.title}</h2>
              <p className="single-line-text">{message.content}</p>
              <div className="message-date-info">
                <span className="message-date">
                  <time dateTime={message.dateTime}>{message.formattedDate}</time>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default TwillioSMS;
  