import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import commonApi from '../../Services/CommonService';
import LoaderSpinner from '../Modals/LoaderSpinner';
import { DEFAULT_IMAGE } from '../../Utils/env';
import { fixName, splitStringToArray } from '../../Utils/commonUtils';
import AudioPlayer from './AudioPlayer';

const CallRecordings = () => {
  const [callRecordingList, setCallRecordingList] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [visibleRecordings, setVisibleRecordings] = useState({});
  const [activeId, setActiveId] = useState(null);

  const handlePlayPause = (id, index) => {
    const uniqueId = `${id}-${index}`;
    setActiveId(prevId => (prevId === uniqueId ? null : uniqueId));
  };

  const get_twilio_call = () => {
    commonApi
      .get_twilio_call()
      .then(res => {
        if (res.status === 200 && res.call_details.length > 0) {
          const filteredData = res.call_details.filter(item => item.recording_urls !== "");
          setCallRecordingList(filteredData);
          setShowSpinner(false);
        }
      })
      .catch(err => console.error('Error fetching Twilio token:', err));
  };

  useEffect(() => {
    get_twilio_call();
  }, []);

  const toggleRecordingsVisibility = (id) => {
    setVisibleRecordings(prev => ({
      ...prev,
      [id]: !prev[id],
    }));
    setActiveId(null)
  };

  return (
    <>
      {showSpinner && <LoaderSpinner />}
      <div className="session-wrapper history-sessions">
        {callRecordingList.length > 0 ? (
          callRecordingList.map((item, index) => {
            const recordings = splitStringToArray(item?.recording_urls || "");
            const isAllVisible = visibleRecordings[item.id];
            return (
              <div
                key={index + 'ASDSAD' + item.id}
                className="events-item cursor-default"
                style={{ padding: '11px', marginBottom: '10px' }}
              >
                <div className="session-heading" style={{ alignItems: 'unset' }}>
                  <div className="persons-img-list mb-0 d-flex align-items-center">
                    <div className="img-item mb-0">
                      <img
                        src={
                          item.receiver_detail?.profile_img
                            ? item.receiver_detail?.profile_img
                            : DEFAULT_IMAGE
                        }
                        alt=""
                        style={{ width: '60px', height: '60px' }}
                      />
                    </div>
                    <div className="row">
                      <span className="m-1">
                        {fixName(
                          item?.receiver_detail?.first_name,
                          item?.receiver_detail?.last_name,
                        )}
                      </span>
                      {item?.receiver_phone && (
                        <span className="m-1">{item?.receiver_phone}</span>
                      )}
                    </div>
                  </div>
                  <div className="row event-titles">
                    <div className="event-date">{item?.status}</div>
                  </div>
                </div>

              {(isAllVisible ? recordings : recordings.slice(0, 1)).map((url, idx) => {
                const uniqueId = `${item.id}-${idx}`;
                return(
                  <div key={idx}>
                    <AudioPlayer src={url} index={idx}
                    isPlaying={activeId === uniqueId}
                    onPlayPause={() => handlePlayPause(item.id, idx)}
                     />
                  </div>
                )})}   

                <div className="session-heading">
                  <div className="event-titles">
                  {recordings.length > 1 && (
                  <button style={{color: '#5ABD42', fontSize: '14px'}} onClick={() => toggleRecordingsVisibility(item.id)}>
                    {isAllVisible ? "Show Less" : "View More"}
                  </button>
                )}
                  </div>
                  <div className="event-date fs-8 cursor-default">
                    <Moment format="MM/DD/YY H:mmA">{item.created_at}</Moment>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-results-wrapper">
            <div className="no-results-content">
              <h2>No records found.</h2>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CallRecordings;
